import { MembershipLevelUpdateRankingPayload } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class MembershipLevelResource extends Resource {
  constructor() {
    super('rms/membership-level');
  }

  update(id: string, resource: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: resource,
    });
  }

  updateRanking(data: MembershipLevelUpdateRankingPayload) {
    return http({
      url: '/' + this.uri + '/' + 'update-serial-number',
      method: 'PUT',
      data,
    });
  }
}

export { MembershipLevelResource as default };
