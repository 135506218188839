import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ThirdPartyPartnerSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  thirdPartyPartnerData: ThirdPartyPartnerSchema;
  index: number;
  search: string;
}

const ThirdPartyPartnerListItem: React.FC<Props> = (props) => {
  const { thirdPartyPartnerData, index, search } = props;
  const { id, name, description, is_active } = thirdPartyPartnerData;

  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();
  const thirdPartyPartnerAPI = new ThirdPartyPartnerResource();

  const deleteThirdPartyPartner = useMutation(
    () => thirdPartyPartnerAPI.destroy(id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('third-party-partner-list');
        if (res.data.success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.third_party_partner_delete,
            status: 'success',
            isClosable: true,
          });
        } else {
          setDeleteMsg(strings.third_party_partner_error);
        }
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const updateThirdPartyPartner = useMutation(
    (data: any) => thirdPartyPartnerAPI.update(id, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('third-party-partner-list');
        setStatusModalOpen(false);
        if (res.data.success) {
          toast({
            title: strings.success,
            status: 'success',
            isClosable: true,
          });
        } else {
          toast({
            title: strings.error_boundary_paragraph_text,
            status: 'success',
            isClosable: true,
          });
        }
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onStatusModalClose = () => {
    setActive(!active);
    setStatusModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteThirdPartyPartner.mutate();
  };

  const [active, setActive] = useState(!!is_active);

  useEffect(() => {
    if (is_active) {
      setActive(is_active);
    }
  }, [is_active]);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setActive(isChecked);
    setStatusModalOpen(true);
  };

  const onStatusUpdate = () => {
    updateThirdPartyPartner.mutate({
      _method: 'PUT',
      is_active: active,
    });
  };

  return (
    <Tr key={id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.thirdPartyPartner.thirdPartyPartner.details.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Text
            fontWeight="medium"
            color="gray.600"
            _hover={{ color: 'gray.900' }}>
            {name ?? '-'}
          </Text>
        </RouterLink>
      </Td>
      <Td>{description ? truncateString(description, 50) : '-'}</Td>
      <Td>
        <Checkbox isChecked={active} onChange={handleStatusChange} />
      </Td>

      <Td>
        <RouterLink
          to={
            routes.thirdPartyPartner.thirdPartyPartner.details.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <RouterLink
          to={
            routes.thirdPartyPartner.thirdPartyPartner.edit.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.edit_third_party_partner}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_third_party_partner}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteThirdPartyPartner.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.is_active}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.formatString(
                strings.message_active_inactive,
                `${active ? strings.active : strings.in_active}`
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="green"
                  onClick={onStatusUpdate}
                  isLoading={updateThirdPartyPartner.isLoading}>
                  {strings.update}
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ThirdPartyPartnerListItem;
