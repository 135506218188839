import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Grid, GridItem } from '@chakra-ui/layout';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ContactSchema } from '../../constants/schema';
interface Props {
  data?: ContactSchema;
}

const ContactDetailInfo: React.FC<Props> = (props) => {
  let { data } = props;

  if (!data) return null;
  const {
    last_name,
    first_name,
    street,
    zip_code,
    location,
    company,
    house_no,
    country,
    phone,
    mobile,
    email,
    is_subscribed,
    contactDetail: {
      fellow_travellers,
      pet,
      equipment_features,
      services,
      internal_notes,
      external_notes,
    },
    membershipLevel,
  } = data;

  return (
    <>
      <Grid
        gap="4"
        templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.first_name}</FormLabel>
            <Box sx={infoStyles}>{first_name ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.last_name}</FormLabel>
            <Box sx={infoStyles}>{last_name ?? '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
            <Box sx={infoStyles}>{street || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.house_no}</FormLabel>
            <Box sx={infoStyles}>{house_no || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
            <Box sx={infoStyles}>{zip_code || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.town}</FormLabel>
            <Box sx={infoStyles}>{location || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
            <Box sx={infoStyles}> {country || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.phone}</FormLabel>
            <Box sx={infoStyles}>
              {phone ? <a href={`tel:${phone}`}>{phone} </a> : '-'}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.mobile}</FormLabel>
            <Box sx={infoStyles}>
              {mobile ? <a href={`tel:${mobile}`}>{mobile} </a> : '-'}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.email}</FormLabel>
            <Box sx={infoStyles}>
              {email ? <a href={`mailto:${email}`}> {email} </a> : '-'}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
            <Box sx={infoStyles}>{company || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.fellow_travelers}</FormLabel>
            <Box sx={infoStyles}>{fellow_travellers || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.pets}</FormLabel>
            <Box sx={infoStyles}>{pet || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.equipment_features}</FormLabel>
            <Box sx={infoStyles}>{equipment_features || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.services}</FormLabel>
            <Box sx={infoStyles}>{services || '-'}</Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.newsletter}</FormLabel>
            <Box sx={infoStyles}>
              {is_subscribed ? strings.subscribe : strings.unsubscribe}
            </Box>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.membership_level}</FormLabel>
            <Box sx={infoStyles}>
              {membershipLevel ? membershipLevel.name : '-'}
            </Box>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.notes_external}</FormLabel>
            <Box sx={infoStyles}>{external_notes || '-'}</Box>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
        <GridItem>
          <FormControl>
            <FormLabel sx={labelStyles}>{strings.notes_internal}</FormLabel>
            <Box sx={infoStyles}>{internal_notes || '-'}</Box>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default ContactDetailInfo;
