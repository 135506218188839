import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useHistory } from 'react-router';
import { changeURL } from 'utils';
import { MembershipLevelFilterParams } from '../../pages/dashboard/thirdPartyPartner/MembershipLevel/MembershipLevelList';

type Props = {
  filterParams: MembershipLevelFilterParams;
  setFilterParams: React.Dispatch<
    React.SetStateAction<MembershipLevelFilterParams>
  >;
};

function MembershipLevelListFilter({ filterParams, setFilterParams }: Props) {
  const history = useHistory();

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      name: '',
      is_active: '',
    }));
    history.push(routes.thirdPartyPartner.membershipLevel.list);
  }

  return (
    <Stack sx={wrapperStyles}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          align={['start', 'end']}>
          <FormControl>
            <FormLabel>{strings.search}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <Input
                type="text"
                name="name"
                value={filterParams.name}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>{strings.status}</FormLabel>
            <Select
              name="is_active"
              value={filterParams.is_active}
              placeholder={`${strings.select} ${strings.status}`}
              onChange={handleInputChange}>
              <option value={1}>{strings.active}</option>
              <option value={0}>{strings.in_active}</option>
            </Select>
          </FormControl>

          <Stack direction="row" spacing="4">
            <Button
              colorScheme="primary"
              variant="outline"
              onClick={handleReset}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default MembershipLevelListFilter;
