import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { selecLabelColorScheme, selectBGScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { NewsletterDetail } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { capitalizeFirstLetter } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  newsletterItem: NewsletterDetail;
  index: number;
  status: string;
  search?: string;
}
const NewsletterListItem: React.FC<Props> = (props) => {
  const { newsletterItem, index, status, search } = props;

  const queryClient = useQueryClient();
  const newsletterDetailsAPI = new NewsletterAPI();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toast = useToast();
  let draftPage: boolean = status === 'pending';

  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:newsletter']],
    routes.newsletter.newsletters.edit.replace(
      ':id',
      newsletterItem.id.toString()
    )
  );

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteNewsletter = useMutation(
    () => newsletterDetailsAPI.destroy(newsletterItem.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('newsLetterDraftList');
        setDeleteModalOpen(false);
        toast({
          title: strings.newsletter_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        setDeleteModalOpen(false);
        toast({
          title: strings.newsletter_delete_error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteNewsletter.mutate();
  };

  const newsletterRecipientGroup = Object.values(
    newsletterItem.recipient_group_names
  );

  return (
    <Tr>
      <Td>{index}</Td>
      <Td isNumeric>{DateFormat(newsletterItem?.updated_at)}</Td>
      <Td>
        <HStack>
          {newsletterRecipientGroup.map((group, index) => (
            <Tag
              key={`${group}${index}`}
              variant="solid"
              rounded="md"
              color={selecLabelColorScheme(group)}
              fontSize="sm"
              size="sm"
              bg={selectBGScheme(group)}>
              <TagLabel>{capitalizeFirstLetter(group)}</TagLabel>
            </Tag>
          ))}
        </HStack>
      </Td>
      <Td>{newsletterItem?.subject}</Td>
      {status === 'confirmed' && (
        <Td isNumeric>{newsletterItem?.recipient_count ?? '-'}</Td>
      )}
      <Td>
        <RouterLink
          to={routes.newsletter.newsletters.view.replace(
            ':id',
            newsletterItem.id.toString() + search
          )}>
          <Tooltip label="View" placement="top-start" hasArrow>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        {draftPage && (
          <>
            <Link onClick={checkEditUserPermission}>
              <Tooltip hasArrow label={strings.edit_newsletter}>
                <IconButton
                  icon={<BiEdit />}
                  variant="link"
                  aria-label={strings.edit_newsletter}
                  color="blue.300"
                  minW="6"
                />
              </Tooltip>
            </Link>
            <Tooltip hasArrow label={strings.delete_newsletter}>
              <IconButton
                icon={<BiTrash />}
                variant="link"
                aria-label={strings.delete_newsletter}
                color="red.300"
                minW="6"
                onClick={onDeletePress}
              />
            </Tooltip>
          </>
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_newsletter}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[PermissionRequest['manage:newsletter']]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    colorScheme="red"
                    onClick={onDeleteConfirm}
                    isLoading={deleteNewsletter.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default NewsletterListItem;
