import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { ExitFullScreen, FullScreen } from 'assets/icons';
import Column from 'components/task/TaskBoard';
import ExportAppointmentTodo from 'components/ticket/ExportAppointmentTodo';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketSearch from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import { DEFAULT_REFETCH_TIME } from 'constants/common';
import routes from 'constants/routes';
import { AppointmentTodoExport, TicketSchema } from 'constants/schema';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiChevronDown, BiFilter } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData, defaultIndexIdentifier } from 'utils';
import {
  DateFormatYMD,
  getDateRangeEndDateTime,
  getDateRangeStartDateTime,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';
import { reorderTickets } from 'utils/reorder';

type TicketBoardType<T> = {
  open: T;
  inProgress: T;
  done: T;
  closed: T;
};
const TICKET_PER_COLUMN_LIMIT = 20;

interface FilterParams {
  title: string;
  priority: string;
  status: string;
  assigned_user_id: string;
  assignee_name: string;
  apartment_id: string;
  customer_id: string;
  customer: string;
  external_company_id: string;
  company: string;
  source: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  due_date_start: Date | null;
  due_date_end: Date | null;
  appointment_date_from: Date | null;
  appointment_date_to: Date | null;
  type: string;
  order_by: string;
  order: any;
  responsible_person_id: string;
  responsible_person_name: string;
}

const TicketBoard = () => {
  /**
   * Initialize
   */
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [defaultIndex, setDefaultIndex] = useState<0 | -1>(
    defaultIndexIdentifier(searchValues)
  );

  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const ticketBoardRef = useRef<any>(null);

  const ticketBoardFullScreenHandler = () => {
    const fullscreenElement = document.fullscreenElement;
    if (!fullscreenElement) {
      ticketBoardRef.current.requestFullscreen &&
        ticketBoardRef.current.requestFullscreen();
    } else {
      document.exitFullscreen && document.exitFullscreen();
    }
  };

  const [appointmentTodoExport, setAppointmentTodoExport] =
    useState<AppointmentTodoExport>(null);

  const [showExportOption, setShowExportOption] = useState<boolean>(false);

  useEffect(() => {
    const escFullScreenListener = () => {
      const isFullScreen: boolean = !!document.fullscreenElement;
      setFullScreen(isFullScreen);
    };
    document.addEventListener('fullscreenchange', escFullScreenListener);
    return () =>
      document.removeEventListener('fullscreenchange', escFullScreenListener);
  }, [fullScreen]);

  const created_at_start = Number(searchValues.created_at_start);
  const created_at_end = Number(searchValues.created_at_end);
  const due_date_start = Number(searchValues.due_date_start);
  const due_date_end = Number(searchValues.due_date_end);
  const appointment_date_from = Number(searchValues.appointment_date_from);
  const appointment_date_to = Number(searchValues.appointment_date_to);

  const [filter, setFilter] = useState<FilterParams>({
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: searchValues.status ?? '',
    assigned_user_id: searchValues.assigned_user_id ?? '',
    assignee_name: searchValues.assignee_name ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    customer_id: searchValues.customer_id ?? '',
    customer: searchValues.customer ?? '',
    external_company_id: searchValues.external_company_id ?? '',
    company: searchValues.company ?? '',
    source: searchValues.source ?? '',
    created_at_start: created_at_start ? new Date(created_at_start) : null,
    created_at_end: created_at_end ? new Date(created_at_end) : null,
    due_date_start: due_date_start ? new Date(due_date_start) : null,
    due_date_end: due_date_end ? new Date(due_date_end) : null,
    appointment_date_from: appointment_date_from
      ? new Date(appointment_date_from)
      : null,
    appointment_date_to: appointment_date_to
      ? new Date(appointment_date_to)
      : null,
    type: searchValues.type ?? '',
    order_by: searchValues.order_by ?? '',
    order: searchValues.order ?? '',
    responsible_person_id: searchValues.responsible_person_id ?? '',
    responsible_person_name: searchValues.responsible_person_name ?? '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [tickets, setTickets] = useState<TicketSchema>({
    open: [],
    inProgress: [],
    done: [],
    closed: [],
  });
  // Meta information for ticket fetch (pagination)
  const [ticketsMeta, setTicketsMeta] = useState<any>({
    open: {},
    inProgress: {},
    done: {},
    closed: {},
  });

  const fetchTicketList = useRef(() => {});

  const ticketApi = new TicketResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const fetchTicketListByStatus = async (status: string, page: number = 1) => {
    const statusToSend = status === 'inProgress' ? 'in-progress' : status;

    const queryData = {
      page: page,
      limit: TICKET_PER_COLUMN_LIMIT,
      title: filter.title,
      priority: filter.priority,
      status: statusToSend,
      assigned_user_id: filter.assigned_user_id,
      assignee_name: filter.assignee_name,
      apartment_id: filter.apartment_id,
      customer_id: filter.customer_id,
      customer: filter.customer,
      external_company_id: filter.external_company_id,
      company: filter.company,
      source: filter.source,
      created_at_start:
        getUTCDateRangeStartDateTime(filter.created_at_end) ?? null,
      created_at_end: getUTCDateRangeEndDateTime(filter.created_at_end) ?? null,
      due_date_start: getDateRangeStartDateTime(filter.due_date_start) ?? null,
      due_date_end: getDateRangeEndDateTime(filter.due_date_end) ?? null,
      appointment_date_from:
        getDateRangeStartDateTime(filter.appointment_date_from) ?? null,
      appointment_date_to:
        getDateRangeEndDateTime(filter.appointment_date_to) ?? null,
      type: filter.type,
      order_by: filter.order_by,
      order: filter.order,
      responsible_person: filter.responsible_person_id,
    };

    const cleanQueryData = cleanData(queryData);
    const response = await ticketApi.list(cleanQueryData);
    return [response.data.data, response.data.meta];
  };

  /**
   * Get Ticket List
   * Status = open , in-progress, done, closed
   */
  fetchTicketList.current = async () => {
    try {
      setIsLoading(true);
      const promises = [
        fetchTicketListByStatus('open'),
        fetchTicketListByStatus('in-progress'),
        fetchTicketListByStatus('done'),
        fetchTicketListByStatus('closed'),
      ];

      const results = await Promise.allSettled(promises);
      const ticketData: TicketBoardType<Object[]> = {
        open: [],
        inProgress: [],
        done: [],
        closed: [],
      };

      const ticketMeta: TicketBoardType<Object> = {
        open: {},
        inProgress: {},
        done: {},
        closed: {},
      };

      const statuses: Array<keyof TicketBoardType<unknown>> = [
        'open',
        'inProgress',
        'done',
        'closed',
      ];

      results.forEach((result, index) => {
        const status = statuses[index];
        if (result.status === 'fulfilled') {
          const [tickets, metadata] = result.value;

          ticketData[status] = tickets || [];
          ticketMeta[status] = metadata || {};
        } else {
          console.error(`Failed to fetch ${status} tickets:`, result.reason);
        }
      });

      setTickets({
        open: ticketData.open,
        inProgress: ticketData.inProgress,
        done: ticketData.done,
        closed: ticketData.closed,
      });
      setTicketsMeta({
        open: ticketMeta.open,
        inProgress: ticketMeta.inProgress,
        done: ticketMeta.done,
        closed: ticketMeta.closed,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetch more tickets for a particular ticket status
   *
   * @param ticketStatus 'open' | 'inProgress' | 'done' | 'closed'
   */
  const handleLoadMore = async (ticketStatus: string) => {
    if (!ticketStatus) return;
    let currentPage = ticketsMeta[ticketStatus].current_page;
    const [ticketList, ticketMeta] = await fetchTicketListByStatus(
      ticketStatus,
      ++currentPage
    );
    setTickets((prevState) => ({
      ...prevState,
      [ticketStatus]: [...prevState[ticketStatus], ...ticketList],
    }));
    setTicketsMeta((prevState: any) => ({
      ...prevState,
      [ticketStatus]: ticketMeta,
    }));
  };

  /**
   * Update total of ticket categories
   *
   * If user picks ticket from `Done` to `Closed`,
   * increase the total of closed by 1 and
   * decrease the total of done by 1
   *
   * @param source sourceId
   * @param destination destinationId
   */
  const updateTotalTickets = (source: string, destination: string) => {
    setTicketsMeta((prevState: any) => ({
      ...prevState,
      [source]: {
        ...prevState[source],
        total: --prevState[source].total,
      },
      [destination]: {
        ...prevState[destination],
        total: ++prevState[destination].total,
      },
    }));
  };

  //  Auto Fetch after 90 second
  useEffect(() => {
    const autoreload = setInterval(() => {
      fetchTicketList.current();
    }, DEFAULT_REFETCH_TIME);
    return () => {
      clearInterval(autoreload);
    };
  }, []);

  useEffect(() => {
    if (filter) fetchTicketList.current();
  }, [filter]);

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) return;

    setTickets(
      reorderTickets(
        tickets,
        source,
        destination,
        loggedInUser.id,
        updateTotalTickets
      )
    );
  };
  const handleAdvancedSearch = useCallback(
    (data: any) => {
      setFilter((prevState: any) => ({
        ...prevState,
        title: data.title,
        priority: data.priority,
        status: data.status,
        assigned_user_id: data.assigned_user_id,
        assignee_name: data.assignee_name,
        apartment_id: data.apartment_id,
        customer_id: data.customer_id,
        customer: data.customer,
        external_company_id: data.external_company_id,
        company: data.company,
        source: data.source,
        created_at_start: data.created_at_start,
        created_at_end: data.created_at_end,
        due_date_start: data.due_date_start,
        due_date_end: data.due_date_end,
        appointment_date_from: data.appointment_date_from,
        appointment_date_to: data.appointment_date_to,
        type: data.type,
        order_by: data.order_by,
        order: data.order,
        responsible_person_id: data.responsible_person_id,
        responsible_person_name: data.responsible_person_name,
      }));

      const urlData = { ...data };
      if (urlData.created_at_start) {
        urlData.created_at_start = Date.parse(urlData.created_at_start);
      }
      if (urlData.created_at_end) {
        urlData.created_at_end = Date.parse(urlData.created_at_end);
      }
      if (urlData.due_date_start) {
        urlData.due_date_start = Date.parse(urlData.due_date_start);
      }
      if (urlData.due_date_end) {
        urlData.due_date_end = Date.parse(urlData.due_date_end);
      }
      if (urlData.appointment_date_from) {
        urlData.appointment_date_from = Date.parse(
          urlData.appointment_date_from
        );
      }
      if (urlData.appointment_date_to) {
        urlData.appointment_date_to = Date.parse(urlData.appointment_date_to);
      }
      delete urlData.status;
      const searchURL = changeURL(urlData);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  const handleAccordionToggle = () => {
    setDefaultIndex((prevState) => (prevState === 0 ? -1 : 0));
  };

  const handleExternalCompanyExportToggle = () => {
    setAppointmentTodoExport('externalCompany');
    setDefaultIndex(0);
  };

  const handleAssigneeExportToggle = () => {
    setAppointmentTodoExport('assignee');
    setDefaultIndex(0);
  };

  useEffect(() => {
    let showExport = false;
    if (appointmentTodoExport === 'externalCompany') {
      showExport =
        !!filter.external_company_id &&
        !!filter.appointment_date_from &&
        !!filter.appointment_date_to;
    } else if (appointmentTodoExport === 'assignee') {
      showExport =
        !!filter.assigned_user_id &&
        !!filter.appointment_date_from &&
        !!filter.appointment_date_to;
    }
    setShowExportOption(showExport);
  }, [
    appointmentTodoExport,
    filter.external_company_id,
    filter.assigned_user_id,
    filter.appointment_date_from,
    filter.appointment_date_to,
  ]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.ticket} | {strings.ticket_board}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.ticket.task.board}>
            {strings.ticket}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_tickets}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading
          size="md"
          textTransform="capitalize"
          display="flex"
          alignItems="center">
          {strings.ticket_board}
          {isLoading && !initialRender && (
            <Center ml={5}>
              <Spinner color="gray.300" size="sm" />
            </Center>
          )}
        </Heading>
        <Stack direction="row">
          <Menu>
            <Tooltip hasArrow label={strings.todo_button_tooltip}>
              <MenuButton
                size="sm"
                as={Button}
                colorScheme="primary"
                rightIcon={<BiChevronDown />}>
                {strings.todo_list}
              </MenuButton>
            </Tooltip>
            <MenuList>
              <Tooltip
                hasArrow
                placement="left"
                label={strings.assignee_button_tooltip}>
                <MenuItem onClick={handleAssigneeExportToggle}>
                  {strings.assignee}
                </MenuItem>
              </Tooltip>
              <Tooltip
                hasArrow
                placement="left"
                label={strings.external_company_button_tooltip}>
                <MenuItem onClick={handleExternalCompanyExportToggle}>
                  {strings.external_company}
                </MenuItem>
              </Tooltip>
            </MenuList>
          </Menu>

          <TicketAddMenu path={routes.ticket.task.create} />

          <Tooltip hasArrow label={strings.full_screen}>
            <Button
              size="sm"
              colorScheme="primary"
              backgroundColor="purple.400"
              type="button"
              padding="8px"
              onClick={ticketBoardFullScreenHandler}>
              <Image
                src={FullScreen}
                alt="Fullscreen"
                w="16px"
                h="16px"
                filter="invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%)"
              />
            </Button>
          </Tooltip>
        </Stack>
      </Flex>

      <Accordion
        bg="white"
        borderColor="white"
        allowToggle
        index={[defaultIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton onClick={handleAccordionToggle}>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <TicketSearch
              isStatus={false}
              handleAdvancedSearch={handleAdvancedSearch}
              filter={filter}
              exportAppointmentTodo={appointmentTodoExport}
              handleResetSearch={() => setAppointmentTodoExport(null)}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {showExportOption && (
        <ExportAppointmentTodo
          exportAppointmentTodo={appointmentTodoExport}
          externalCompanyId={filter.external_company_id}
          externalCompanyName={filter.company}
          assigneeId={filter.assigned_user_id}
          assigneeName={filter.assignee_name}
          appointmentDateFrom={DateFormatYMD(filter.appointment_date_from)}
          appointmentDateTo={DateFormatYMD(filter.appointment_date_to)}
        />
      )}

      {isError && (
        <Alert status="error">
          <AlertIcon />
          {strings.ticket_list_error}
        </Alert>
      )}

      <Stack
        direction="row"
        spacing="4"
        width="100%"
        overflow="auto hidden"
        marginBottom="50px !important"
        backgroundColor="gray.50"
        position="relative"
        ref={ticketBoardRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.entries(tickets).map(([key, value]) => (
            <Column
              key={key}
              ticketStatus={key}
              ticketList={value}
              ticketMeta={ticketsMeta[key]}
              handleLoadMore={handleLoadMore}
              isLoading={isLoading}
              initialRender={initialRender}
            />
          ))}
        </DragDropContext>
        {fullScreen && (
          <>
            <Button
              size="md"
              colorScheme="primary"
              backgroundColor="purple.400"
              type="button"
              position="absolute"
              bottom="10px"
              right="10px"
              padding="8px"
              onClick={ticketBoardFullScreenHandler}>
              <Image
                src={ExitFullScreen}
                alt="Exit Fullscreen"
                w="16px"
                h="16px"
                filter="invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%)"
              />
            </Button>
            {isLoading && (
              <Box
                w="100%"
                backgroundColor="transparent"
                position="absolute"
                top="0px"
                marginInlineStart="0px !important">
                <Center
                  width="max-content"
                  margin="auto"
                  borderRadius="50%"
                  backgroundColor="gray.50"
                  padding="10px">
                  <Spinner color="gray.300" size="lg" />
                </Center>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default TicketBoard;
