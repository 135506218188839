import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MembershipLevelResource from 'api/membership-level';
import { CenterSpinner } from 'components/common/CenterSpinner';
import MembershipLevelForm from 'components/third_party_partner/MembershipLevelForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { MembershipLevelSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const AddMembershipLevel: React.FC = () => {
  const { id: membershipLevelId }: any = useParams();
  const { search } = useLocation();

  const methods = useForm<MembershipLevelSchema>({
    defaultValues: {
      booking_discount: 0,
      final_cleaning_discount: 0,
      laundry_discount: 0,
      website_condition_discount: 0,
      night_spent: 0,
      euro_spent_in_cents: 0,
      number_of_booking: 0,
    },
  });
  const toast = useToast();
  const history = useHistory();
  const membershipLevelAPI = new MembershipLevelResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: membershipLevelDetail } = useQuery({
    queryKey: ['membership-level-detail-page', membershipLevelId],
    queryFn: async () => {
      const response = await membershipLevelAPI.get(membershipLevelId);
      return response.data.data;
    },
    enabled: !!membershipLevelId,
  });

  const createMembershipLevel = useMutation(
    (data: any) => membershipLevelAPI.store(data),
    {
      onSuccess: (res: any) => {
        const id = res?.data?.data?.id;
        history.push(
          routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            id.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const updateMembershipLevel = useMutation(
    (data: any) => membershipLevelAPI.update(membershipLevelId, data),
    {
      onSuccess: () => {
        history.push(
          routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            membershipLevelId.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = async (data: any) => {
    let API;
    const formData = new FormData();

    if (data.icons && data.icons.size > 0) {
      formData.append('icon', data.icons);
    }
    formData.append('name', data.name);
    if (data.description) formData.append('description', data.description);
    if (data.brief_description)
      formData.append('brief_description', data.brief_description);
    formData.append('is_internal', data.is_internal ? '1' : '0');
    formData.append('is_published', data.is_published ? '1' : '0');
    formData.append('is_active', data.is_active ? '1' : '0');
    formData.append('booking_discount', data.booking_discount || 0);
    formData.append(
      'final_cleaning_discount',
      data.final_cleaning_discount || 0
    );
    formData.append('laundry_discount', data.laundry_discount || 0);
    formData.append(
      'website_condition_discount',
      data.website_condition_discount || 0
    );
    formData.append('night_spent', data.night_spent || 0);
    formData.append('number_of_booking', data.number_of_booking || 0);
    const euroSpent = data.euro_spent_in_cents
      ? data.euro_spent_in_cents * 100
      : 0;
    formData.append('euro_spent_in_cents', euroSpent.toString());

    // Append each ID in partner_goodies_ids array individually
    if (data.partner_goodies_ids && data.partner_goodies_ids.length > 0) {
      if (Array.isArray(data.partner_goodies_ids)) {
        data.partner_goodies_ids.forEach((id: any) => {
          formData.append('partner_goodies_ids[]', String(id));
        });
      } else {
        // If it's not an array, assume it's a single ID and append it
        formData.append(
          'partner_goodies_ids[]',
          String(data.partner_goodies_ids)
        );
      }
    }

    if (membershipLevelId) {
      API = updateMembershipLevel;
      formData.append('_method', 'PUT');
    } else {
      API = createMembershipLevel;
    }

    setIsLoading(true);
    API.mutate(formData, {
      onSuccess: () => {
        toast({
          title: `${strings.membership_level} ${
            membershipLevelId
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(
          `${strings.membership_level} ${
            membershipLevelId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  if (membershipLevelDetail?.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} | &nbsp;
            {membershipLevelId
              ? strings.edit_member_level
              : strings.add_new_member_level}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.membershipLevel.list + search}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {membershipLevelId
                ? strings.edit_member_level
                : strings.add_new_member_level}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {membershipLevelId
              ? strings.edit_member_level
              : strings.add_new_member_level}
          </Heading>
        </Flex>
        <Stack>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}
                <MembershipLevelForm data={membershipLevelDetail} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!membershipLevelId ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddMembershipLevel;
