import Resource from 'api/resource';
import axios from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class TicketResource extends Resource {
  constructor() {
    super('ticket/tickets');
  }

  list(query?: any) {
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }
  assignedTicket(userId: number) {
    return http({
      url: `/ticket/assignee/${userId}/tickets`,
      method: 'get',
    });
  }

  updateTicketStatus(ticketId: number, status: string, updatedBy: number) {
    return http({
      url: `/ticket/tickets/${ticketId}/update-status`,
      method: 'put',
      data: {
        status,
        updated_by: updatedBy,
      },
    });
  }

  getTicketComment(ticketId: number, page: number, limit: number) {
    return http({
      url: `/ticket/tickets/${ticketId}/comments`,
      method: 'get',
      params: {
        page,
        limit,
      },
    });
  }

  getTickeLog(ticketId: number, page: number, limit: number) {
    return http({
      url: `/ticket/tickets/${ticketId}/ticket-activities`,
      method: 'get',
      params: {
        page,
        limit,
      },
    });
  }

  ticketComment(
    ticketId: number,
    comment: string,
    createdBy: number,
    parentId?: number
  ) {
    return http({
      url: `/ticket/tickets/${ticketId}/comments`,
      method: 'post',
      data: {
        comment,
        created_by: createdBy,
        parent_id: parentId,
      },
    });
  }

  getApartmentList() {
    return http({
      url: `/ticket/apartments`,
      method: 'get',
    });
  }

  getCustomerList() {
    return http({
      url: `/ticket/customers`,
      method: 'get',
    });
  }

  /**
   * Used for Assignee
   */
  getUserList() {
    return http({
      url: `/ticket/users`,
      method: 'get',
    });
  }

  /**
   * update billable spent time
   */
  updateTicketBillableTime(
    ticketId: number,
    spentTime: string,
    isSpentTimeUpdated: boolean,
    updatedBy: number
  ) {
    return http({
      url: `/ticket/tickets/${ticketId}/update-spent-time`,
      method: 'put',
      data: {
        spent_time: spentTime,
        is_spent_time_updated: isSpentTimeUpdated,
        updated_by: updatedBy,
      },
    });
  }

  /**
   * Upload to Bucket
   */
  uploadToBucket(data: any) {
    return http({
      url: `/ticket/tickets/attachment`,
      method: 'post',
      data: data,
    });
  }

  /**
   * Delete Attachment
   */
  destroyAttachment(attachmentId: number) {
    return http({
      url: `/ticket/tickets/attachment/${attachmentId}`,
      method: 'delete',
    });
  }

  /**
   * Save Data of MMS Transfer in Log
   */
  createTransferLog(ticketId: number, data: any) {
    return http({
      url: `/ticket/tickets/${ticketId}/transfer`,
      method: 'post',
      data: data,
    });
  }

  /**
   * get Apartment Wise Ticket Count
   */
  getApartmentWiseTicket(page: number, limit: number, apartmentName: string) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: `/ticket/apartment-tickets`,
      method: 'get',
      params: {
        page,
        limit,
        apartment_name: apartmentName,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  exportAssigneeTodo(id: string, query: any) {
    return http({
      url: `/ticket/export/assignee/${id}`,
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }

  exportExternalCompanyTodo(id: string, query: any) {
    return http({
      url: `/ticket/export/externalCompany/${id}`,
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }

  exportTicket(query?: any) {
    return http({
      url: `/ticket/export/tickets`,
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }
}

export { TicketResource as default };
