import {
  Flex,
  IconButton,
  Stack,
  Switch,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import RMSObjectsResource from 'api/rms-objects';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import { apartmentWithVRTour } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { BiLinkExternal, BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  data: any;
  startingSN: number;
  search?: string;
  queryKey: [string, Record<string, string | number>];
}

const ObjektListItems: React.FC<Props> = (props) => {
  const { data, startingSN, search, queryKey } = props;

  const {
    id: objectID,
    status,
    has_payment_enable,
    has_7_for_6_offer,
    has_video_active,
    has_vr_active,
    has_sylt_go,
    dynamic_pricing_enable,
  } = data;

  const hasVR: boolean = apartmentWithVRTour.includes(objectID);
  const disableVRSwitch: boolean = !hasVR;

  const toast = useToast();
  const apartmentAPI = new RMSObjectsResource();
  const queryClient = useQueryClient();

  const updateObject = useMutation(
    (data: any) => apartmentAPI.update(objectID, data),
    {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );

  const hasDashboardPermission = useIsPermitted([
    PermissionRequest['read:object-dashboard'],
  ]);

  function handleChangeStatus(e: any) {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateObject.mutate(data);
  }

  function getType(type: string) {
    switch (type) {
      case 'HOUSE':
        return strings.house.toUpperCase();

      case 'FLAT':
        return strings.flat.toUpperCase();

      default:
        return '';
    }
  }

  const {
    person_in_charge,
    substitute_person_in_charge,
    info: { fullName, type, address },
  } = data;

  const { city, street, housenumber } = address;

  const displayPersonInCharge =
    (person_in_charge?.name || substitute_person_in_charge?.name) ?? '-';

  return (
    <Tr>
      <Td>{startingSN}</Td>
      <Td>
        <RouterLink
          to={
            routes.oms.objects.details.replace(':id', objectID.toString()) +
            search
          }>
          <Flex>
            <CustomTagTitle
              type="text"
              status={status}
              name={fullName || '-'}
            />
          </Flex>
        </RouterLink>
      </Td>
      <Td>
        <CustomTagTitle type="text" status={status} name={getType(type)} />
      </Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={status}
          name={`${city}, ${street}, ${housenumber}`}
        />
      </Td>
      <Td style={{ textTransform: 'capitalize' }}>
        <CustomTagTitle
          type="text"
          status={status}
          name={displayPersonInCharge}
        />
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="has_payment_enable"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={has_payment_enable}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="status"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={status}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="dynamic_pricing_enable"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={dynamic_pricing_enable}
          />
        </Stack>
      </Td>

      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="has_video_active"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={has_video_active}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="has_vr_active"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={has_vr_active}
            isDisabled={disableVRSwitch}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="has_7_for_6_offer"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={has_7_for_6_offer}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="has_sylt_go"
            colorScheme="green"
            onChange={handleChangeStatus}
            isChecked={has_sylt_go}
          />
        </Stack>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.oms.objects.details.replace(':id', objectID.toString()) +
            search
          }>
          <Tooltip hasArrow label="View ">
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        {hasDashboardPermission && (
          <RouterLink
            target="_blank"
            to={routes.objectDashboard.dashboard.objectBoard.replace(
              ':id',
              objectID.toString()
            )}>
            <Tooltip hasArrow label={strings.dashboard}>
              <IconButton
                icon={<BiLinkExternal />}
                variant="link"
                aria-label={strings.dashboard}
                minW="6"
              />
            </Tooltip>
          </RouterLink>
        )}
      </Td>
    </Tr>
  );
};

export default ObjektListItems;
