import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import {
  Files,
  InstagramCircle,
  PinterestCircle,
  VimeoCircle,
} from 'assets/icons';
import { BusinessSolutionLogo } from 'assets/images';
import { strings } from 'config/localization';
import {
  IMPRINT_URL,
  INSTAGRAM_URL,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  VIMEO_URL,
  WEBSITE_URL,
} from 'constants/common';
import routes from 'constants/routes';
import { NewsletterDetail, NewsletterGroupList } from 'constants/schema';
import FileSaver from 'file-saver';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BiCloudDownload, BiPaperclip } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';

const ViewNewsletterDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const newsletterDetailsAPI = new NewsletterAPI();
  const { search } = useLocation();

  const { data: newsLetterDetail } = useQuery<NewsletterDetail>(
    [`newsletterDetail`],
    async () => {
      return await newsletterDetailsAPI.get(id).then((res) => res.data.data);
    }
  );

  const {
    data: newsletterRecipientGroup,
    isLoading: isNewsletterRecipientGroupLoading,
  } = useQuery<NewsletterGroupList[]>({
    queryKey: ['newsletter-groups'],
    queryFn: async () => {
      const response = await newsletterDetailsAPI.getRecipientGroupList();
      return response.data?.data;
    },
  });

  const getRecipientsName = (id: number) => {
    const recipientDetail = newsletterRecipientGroup?.find(
      (item) => item.id === id
    );
    if (!recipientDetail) return '-';
    return capitalizeFirstLetter(recipientDetail.name);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.newsletter} | {strings.preview_message}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                newsLetterDetail?.status === 'confirmed'
                  ? routes.newsletter.newsletters.list + search
                  : routes.newsletter.draft.list + search
              }>
              {newsLetterDetail?.status === 'confirmed'
                ? strings.newsletters
                : strings.drafts}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.preview_message}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.preview_message}
          </Heading>
          {newsLetterDetail?.status === 'pending' && (
            <RouterLink
              to={`${routes.newsletter.newsletters.edit.replace(
                ':id',
                id.toString() + search
              )}`}>
              <Button colorScheme="primary" size="sm" type="button">
                {strings.edit}
              </Button>
            </RouterLink>
          )}
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['4', '32']}
          shadow="box"
          w="full">
          <Stack dir="column">
            <Flex>
              {strings.subject}:&nbsp;
              <Box fontWeight="bold">{newsLetterDetail?.subject}</Box>
            </Flex>
            <Flex>
              {strings.group_of_recipients}:
              {newsLetterDetail?.recipient_group?.map(
                (item: any, id: number) => (
                  <Flex pl="4" key={id}>
                    {id !== 0 && <Divider orientation="vertical" pl="3" />}
                    <Box
                      fontWeight="bold"
                      textTransform="capitalize"
                      alignSelf="center">
                      {getRecipientsName(item)}
                    </Box>
                  </Flex>
                )
              )}
            </Flex>
          </Stack>
          <Stack
            direction="column"
            spacing="4"
            bg="gray.100"
            shadow="box"
            w="full">
            <Stack spacing="4" p="4">
              <Center>
                <Image
                  src={BusinessSolutionLogo}
                  width="340px"
                  objectFit="contain"
                />
              </Center>
            </Stack>
            <Divider borderColor="gray.300" marginTop="0 !important" />
            <Stack
              dir="column"
              spacing="4"
              height="auto"
              p="8"
              marginTop="0 !important">
              <Editor
                tinymceScriptSrc={
                  process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                }
                disabled={true}
                inline={true}
                initialValue={newsLetterDetail?.message}
              />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing="4"
            bg="gray.200"
            color="gray.700"
            shadow="box"
            p={['0', '4']}
            marginTop="8px !important"
            w="full">
            <Center>
              <Flex paddingInline="4">
                <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
                  <Box fontWeight="bold" textTransform="capitalize">
                    Website
                  </Box>
                </a>
              </Flex>
              <Text fontSize="20px" fontWeight="bold">
                |
              </Text>
              <Flex paddingInline="4">
                <a
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Box fontWeight="bold" textTransform="capitalize">
                    Datenschutz
                  </Box>
                </a>
              </Flex>
              <Text fontSize="20px" fontWeight="bold">
                |
              </Text>
              <Flex paddingInline="4">
                <a href={IMPRINT_URL} target="_blank" rel="noopener noreferrer">
                  <Box fontWeight="bold" textTransform="capitalize">
                    Impressum
                  </Box>
                </a>
              </Flex>
            </Center>
            <Stack
              dir="column"
              spacing="4"
              height="auto"
              p="4"
              marginTop="0 !important">
              <Grid
                gap="2"
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                w="100%">
                <GridItem span={6} mb={2}>
                  <Box>
                    <Text>Appartementvermittlung Familie</Text>
                    <Text>Clausen GmbH</Text>
                    <Text>Am Buttgraben 6a</Text>
                    <Text>25992 List</Text>
                  </Box>
                </GridItem>
                <GridItem span={6} mb={2}>
                  <Box>
                    <Text>Telefon: 04651 889 8570</Text>
                    <Text>Telefax: 04651 877 551</Text>
                    <Text>eMail: info@listinfo.de</Text>
                    <Text>Internet: www.list-info.de</Text>
                  </Box>
                </GridItem>
                <GridItem span={6} mb={2}>
                  <Box>
                    <Text>
                      Geschäftsführer/in: Cornelia Clausen, Jörn Clausen
                    </Text>
                    <Text>Amtsgericht Flensburg: HRB 10714 FL</Text>
                    <Text>Erfüllungsort: List</Text>
                    <Text>Ust.-IdNr: DE297105589</Text>
                  </Box>
                </GridItem>
                <GridItem span={6} mb={2}>
                  <Box>
                    <Text>Volskbank Schleswig</Text>
                    <Flex>
                      <Text>IBAN: DE72 2169 0020 0001 4526 81</Text>
                    </Flex>
                    <Text>BIC: GENODEF1SLW</Text>
                  </Box>
                </GridItem>
              </Grid>
              <Center>
                <Box textAlign="center">
                  <Text>
                    Sie erhalten diese E-Mail, weil sie den Newsletter der
                    Appartementvermittlung Familie Clausen abonniert haben.
                  </Text>
                  <Flex w="max-content" margin="auto">
                    <Text>Nicht mehr interessiert?</Text>&nbsp;
                    <Text color="blue" textDecoration="underline">
                      Hier abmelden.
                    </Text>
                  </Flex>
                </Box>
              </Center>
            </Stack>

            {/* <Stack dir="column"> //just in case it is need for future
                <Text color="gray.400">{strings.download_our_app_now}</Text>
                <Flex>
                  <Image src={GooglePlay} w="24" />
                  <Spacer />
                  <Image src={AppStore} w="24" ml="4" />
                </Flex>
              </Stack> */}
            <Center>
              <Flex>
                <Tooltip label="Instagram" hasArrow>
                  <a
                    href={INSTAGRAM_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Box
                      bg="white"
                      shadow="box"
                      w={['14', '16']}
                      h={['14', '16']}
                      rounded="full"
                      marginInline="3"
                      p="3">
                      <Image src={InstagramCircle} />
                    </Box>
                  </a>
                </Tooltip>
                <Tooltip label="Vemo" hasArrow>
                  <a href={VIMEO_URL} target="_blank" rel="noopener noreferrer">
                    <Box
                      bg="white"
                      shadow="box"
                      w={['14', '16']}
                      h={['14', '16']}
                      rounded="full"
                      marginInline="3"
                      p="3">
                      <Image src={VimeoCircle} />
                    </Box>
                  </a>
                </Tooltip>
                <Tooltip label="Pinterest" hasArrow>
                  <a
                    href={PINTEREST_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Box
                      bg="white"
                      shadow="box"
                      w={['14', '16']}
                      h={['14', '16']}
                      rounded="full"
                      marginInline="3"
                      p="3">
                      <Image src={PinterestCircle} />
                    </Box>
                  </a>
                </Tooltip>
              </Flex>
            </Center>
            <Center>
              <Text textAlign="center">
                &copy; 2022 Appartementvermittlung Familie Clausen GmbH
              </Text>
            </Center>
          </Stack>
          <Stack spacing="4" dir="row">
            {newsLetterDetail && newsLetterDetail?.newsletterFiles?.length > 0 && (
              <Flex justifyItems="center">
                <Icon as={BiPaperclip} mr="2" />
                <Text fontWeight="bold">{strings.attachments}</Text>
              </Flex>
            )}
            <Flex flexWrap={'wrap'}>
              {newsLetterDetail?.newsletterFiles?.map(
                (file: any, index: number) => (
                  <Flex pl="6" key={index}>
                    <Image
                      src={
                        file.mime_type.includes('image') ? file.file_url : Files
                      }
                      w="8"
                    />
                    <Stack dir="column" ml="2">
                      <Text color="gray.600">{file.file_name}</Text>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start">
                        <Text
                          color="gray.300"
                          fontSize="sm"
                          mt="0 !important"
                          pr="2">
                          {file?.file_size / (1024 * 1024) >= 1
                            ? (file?.file_size / (1024 * 1024)).toFixed(1) +
                              'MB'
                            : (file?.file_size / 1024).toFixed(1) + 'KB'}
                        </Text>
                        <Tooltip hasArrow label={strings.download}>
                          <IconButton
                            icon={<BiCloudDownload />}
                            variant="link"
                            aria-label={strings.download}
                            minW="6"
                            onClick={() =>
                              FileSaver.saveAs(file.file_url, file.file_url)
                            }
                          />
                        </Tooltip>
                      </Box>
                    </Stack>
                  </Flex>
                )
              )}
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewNewsletterDetails;
