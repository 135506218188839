import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { cleanData } from 'utils';
import { getFormattedRangeDate } from 'utils/DateFormat';

const LABEL = [
  { value: 'owner', label: 'owner' },
  { value: 'guest', label: 'guest' },
  { value: 'app', label: 'web_app' },
  { value: 'lead', label: 'lead' },
  { value: 'supplier', label: 'supplier' },
] as const;

interface Props {
  filterParams: any;
  handleAdvancedSearch: any;
  handleResetSearch?: () => void;
}

const InitialValue = {
  first_name: '',
  last_name: '',
  type: '',
  source: '',
  is_merged: '',
  email: '',
  phone: '',
  mobile: '',
  location: '',
  street: '',
  newsletter_subscribed: '',
};

const ContactSearch: React.FC<Props> = (props) => {
  const { filterParams, handleAdvancedSearch, handleResetSearch } = props;
  const firstRender = useRef(true);

  const history = useHistory();

  const [startDate, setStartDate] = useState<Date | null>(
    filterParams?.updated_at_from
      ? new Date(filterParams?.updated_at_from)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    filterParams?.updated_at_to ? new Date(filterParams?.updated_at_to) : null
  );
  const [formValue, setFormValue] = useState<any>({
    first_name: filterParams.first_name ?? '',
    last_name: filterParams.last_name ?? '',
    type: filterParams.type ?? '',
    source: filterParams.source ?? '',
    is_merged: filterParams.is_merged ?? '',
    email: filterParams.email ?? '',
    phone: filterParams.phone ?? '',
    mobile: filterParams.mobile ?? '',
    location: filterParams.location ?? '',
    street: filterParams.street ?? '',
    newsletter_subscribed: filterParams.newsletter_subscribed ?? '',
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const finalData: any = cleanData(formValue);
    if (startDate) finalData.updated_at_from = startDate;
    if (endDate) finalData.updated_at_to = endDate;
    handleAdvancedSearch(finalData);
  }, [endDate, formValue, handleAdvancedSearch, startDate]);

  const handleReset = () => {
    setFormValue(InitialValue);
    setStartDate(null);
    setEndDate(null);
    let selectRoute = '';
    if (history.location.pathname.includes(routes.crm.contact.list))
      selectRoute = routes.crm.contact.list;
    else if (history.location.pathname.includes(routes.crm.merge.list))
      selectRoute = routes.crm.merge.list;
    else if (
      history.location.pathname.includes(
        routes.objectDashboard.dashboard.contactList
      )
    )
      selectRoute = routes.objectDashboard.dashboard.contactList;
    history.push(selectRoute);

    if (handleResetSearch !== undefined) handleResetSearch();
    else handleAdvancedSearch({});
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.first_name}</FormLabel>
                  <Input
                    type="text"
                    name="first_name"
                    value={formValue.first_name}
                    placeholder={strings.first_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.last_name}</FormLabel>
                  <Input
                    type="text"
                    name="last_name"
                    value={formValue.last_name}
                    placeholder={strings.last_name}
                    onChange={handleInputChange}
                    autoFocus
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.label}</FormLabel>
                  <Select
                    rounded="sm"
                    name="type"
                    value={formValue.type}
                    placeholder={strings.select}
                    onChange={handleInputChange}>
                    {LABEL.map((item) => (
                      <option key={item.value} value={item.value}>
                        {strings[item.label]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.source}</FormLabel>
                  <Select
                    rounded="sm"
                    name="source"
                    value={formValue.source}
                    placeholder={strings.select_source}
                    onChange={handleInputChange}>
                    <option value="manual">{strings.manual}</option>
                    <option value="v-office">VOffice</option>
                    <option value="newsletter">{strings.newsletter}</option>
                    <option value="app">{strings.web_app}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.merge}</FormLabel>
                  <Select
                    name="is_merged"
                    value={formValue.is_merged}
                    placeholder={`${strings.select} ${strings.merge}`}
                    onChange={handleInputChange}>
                    <option value="true">{strings.merge}</option>
                    <option value="false">{strings.unmerge}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.email}</FormLabel>

                  <Input
                    name="email"
                    value={formValue.email}
                    type="text"
                    placeholder={strings.email}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.phone_number}</FormLabel>
                  <Input
                    type="text"
                    name="phone"
                    value={formValue.phone}
                    placeholder={strings.phone_number}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.mobile}</FormLabel>
                  <Input
                    type="text"
                    name="mobile"
                    value={formValue.mobile}
                    placeholder={strings.mobile_number}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.added_on}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleDateChange}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.city}</FormLabel>

                  <Input
                    type="text"
                    name="location"
                    value={formValue.location}
                    placeholder={strings.city}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.street}</FormLabel>
                  <Input
                    name="street"
                    value={formValue.street}
                    type="text"
                    placeholder={strings.street}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.newsletter}</FormLabel>
                  <Select
                    name="newsletter_subscribed"
                    value={formValue.newsletter_subscribed}
                    placeholder={`${strings.select} ${strings.newsletter}`}
                    onChange={handleInputChange}>
                    <option value="true">{strings.subscribe}</option>
                    <option value="false">{strings.unsubscribe}</option>
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4">
            <Button
              colorScheme="primary"
              onClick={handleReset}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ContactSearch;
