import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  Stack,
  Switch,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import RMSObjectsResource from 'api/rms-objects';
import RMSVOfficeResource from 'api/rms-v-office';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ObjecktDetailsResponsibilities from 'components/rms/ObjecktDetailsResponsibilities';
import ObjektAddImages from 'components/rms/ObjektAddImages';
import ObjektDetailsInfo from 'components/rms/ObjektDetailsInfo';
import ObjektTiers from 'components/rms/ObjektTiers';
import { strings } from 'config/localization';
import { apartmentWithVRTour } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ObjektDetailSchema } from 'constants/schemas/objektSchema';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { BiHelpCircle, BiInfoCircle, BiLinkExternal } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

const ObjektDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const objectID = Number(id);
  const queryClient = useQueryClient();
  const toast = useToast();
  const methods = useForm<any>();
  const { search } = useLocation();
  const { handleSubmit, register, setValue } = methods;

  const apartmentAPI = new RMSObjectsResource();
  const vOfficeAPI = new RMSVOfficeResource();

  const apartmentDetailKey = `apartmentDetails-${objectID}`;

  const {
    data: apartmentDetails,
    isLoading,
    isError,
    isFetching,
  } = useQuery<ObjektDetailSchema>(
    apartmentDetailKey,
    async () => {
      const response = await apartmentAPI.get(objectID);
      const { video_url } = response?.data?.data;
      setValue('video_url', video_url);
      return response?.data?.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const updateObject = useMutation(
    (data: any) => apartmentAPI.update(objectID, data),
    {
      onMutate: async (updateData) => {
        await queryClient.cancelQueries(apartmentDetailKey);
        const previousData = queryClient.getQueryData(apartmentDetailKey);
        queryClient.setQueryData(apartmentDetailKey, (oldQueryData: any) => ({
          ...oldQueryData,
          ...updateData,
        }));
        return previousData;
      },
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(apartmentDetailKey);
      },
      onError: (_err, _data, context) => {
        queryClient.setQueryData(apartmentDetailKey, context);
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => queryClient.invalidateQueries(apartmentDetailKey),
    }
  );

  const hasDashboardPermission = useIsPermitted([
    PermissionRequest['read:object-dashboard'],
  ]);

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }
  if (isError || !apartmentDetails) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.cannot_get_apartment_details}
      </Alert>
    );
  }

  const {
    status,
    has_payment_enable,
    has_7_for_6_offer,
    has_video_active,
    has_vr_active,
    dynamic_pricing_enable,
  } = apartmentDetails;

  const {
    name: { de: apartmentName },
  } = apartmentDetails?.info;

  const hasVR: boolean = apartmentWithVRTour.includes(objectID);
  const disableVRSwitch: boolean = !hasVR;
  const disableVideoSwitch: boolean = !apartmentDetails?.video_url?.length;

  function handleChangeStatus(e: any) {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateObject.mutate(data);
  }

  const handleVofficeSync = async () => {
    const response = await vOfficeAPI.get(objectID);
    if (response?.data?.success) {
      toast({
        title: strings.data_synced,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(`apartmentDetails-${objectID}`);
    }
  };

  const {
    person_in_charge,
    substitute_person_in_charge,
    inform_owner_repairs,
    owner_repairs_allowed,
    allowed_repairs_max_price,
    notes,
  } = apartmentDetails;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.oms} | {apartmentName}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.oms.objects.list + search}>
              {strings.object_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {apartmentName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.object_details}
          </Heading>
          <Flex gridGap="4" alignItems="center">
            {hasDashboardPermission && (
              <Tooltip hasArrow placement="top" label={strings.dashboard}>
                <RouterLink
                  target="_blank"
                  to={routes.objectDashboard.dashboard.objectBoard.replace(
                    ':id',
                    id
                  )}>
                  <Icon
                    aria-label={strings.dashboard}
                    as={BiLinkExternal}
                    h="6"
                    w="6"
                  />
                </RouterLink>
              </Tooltip>
            )}
            <ObjektAddImages />
            <AccessControl
              allowedPermissions={[PermissionRequest['sync:voffice']]}
              renderNoAccess={(data: any) => data}>
              <Button
                bg="primary.400"
                colorScheme="primary"
                onClick={handleVofficeSync}
                size="sm">
                {strings.voffice_data_sync}
              </Button>
            </AccessControl>
          </Flex>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form onSubmit={handleSubmit((data) => updateObject.mutate(data))}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>Vimeo-Video Link (optional)</FormLabel>
                <Input
                  type="url"
                  {...register('video_url')}
                  placeholder="Vimeo-Video Link (optional)"
                />
              </FormControl>
              <Stack direction="row" spacing="4">
                <Button colorScheme="primary" type="submit">
                  {strings.save_now}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Heading size="sm">{strings.settings}</Heading>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            flex="1"
            w="100%">
            <GridItem>
              <FormLabel>{strings.payement_active}</FormLabel>
              <Switch
                size="lg"
                colorScheme="green"
                name="has_payment_enable"
                isChecked={Boolean(has_payment_enable)}
                onChange={handleChangeStatus}
              />
            </GridItem>
            <GridItem>
              <FormLabel>{strings.website_active}</FormLabel>
              <Switch
                size="lg"
                colorScheme="green"
                name="status"
                isChecked={Boolean(status)}
                onChange={handleChangeStatus}
              />
            </GridItem>
            <GridItem>
              <FormLabel>
                <Flex direction="row" alignItems="center">
                  {strings.video_active}
                  <Tooltip
                    hasArrow
                    label={strings.can_only_be_enabled_object_with_video_url}
                    size="sm">
                    <IconButton
                      icon={<BiHelpCircle />}
                      size="sm"
                      variant="ghost"
                      color="gray.500"
                      aria-label={
                        strings.can_only_be_enabled_object_with_video_url
                      }
                      ml="1"
                    />
                  </Tooltip>
                </Flex>
              </FormLabel>
              <Switch
                size="lg"
                colorScheme="green"
                name="has_video_active"
                isChecked={Boolean(has_video_active)}
                onChange={handleChangeStatus}
                isDisabled={disableVideoSwitch}
              />
            </GridItem>
            <GridItem>
              <FormLabel>
                <Flex direction="row" alignItems="center">
                  {strings.three_sixty_active}
                  <Tooltip
                    hasArrow
                    label={strings.can_only_be_enabled_apartment_with_VR}
                    size="sm">
                    <IconButton
                      icon={<BiHelpCircle />}
                      size="sm"
                      variant="ghost"
                      color="gray.500"
                      aria-label={strings.can_only_be_enabled_apartment_with_VR}
                      ml="1"
                    />
                  </Tooltip>
                </Flex>
              </FormLabel>
              <Switch
                size="lg"
                colorScheme="green"
                name="has_vr_active"
                isChecked={Boolean(has_vr_active)}
                onChange={handleChangeStatus}
                isDisabled={disableVRSwitch}
              />
            </GridItem>
            <GridItem>
              <FormLabel>
                <Flex direction="row" align="center">
                  {strings.seven_for_six}
                  <Tooltip
                    hasArrow
                    label={strings.this_discount_will_be_applied_for_season_c}
                    size="sm">
                    <IconButton
                      icon={<BiHelpCircle />}
                      size="sm"
                      variant="ghost"
                      color="gray.500"
                      aria-label={
                        strings.this_discount_will_be_applied_for_season_c
                      }
                      ml="1"
                    />
                  </Tooltip>
                </Flex>
              </FormLabel>

              <Switch
                size="lg"
                colorScheme="green"
                name="has_7_for_6_offer"
                isChecked={Boolean(has_7_for_6_offer)}
                onChange={handleChangeStatus}
              />
            </GridItem>
            <GridItem>
              <FormLabel>{strings.dynamic_pricing}</FormLabel>
              <Switch
                size="lg"
                colorScheme="green"
                name="dynamic_pricing_enable"
                isChecked={Boolean(dynamic_pricing_enable)}
                onChange={handleChangeStatus}
              />
            </GridItem>
          </Grid>
        </Stack>

        <ObjecktDetailsResponsibilities
          apartmentId={objectID}
          person_in_charge={person_in_charge}
          substitute_person_in_charge={substitute_person_in_charge}
          apartmentDetailKey={apartmentDetailKey}
        />

        <ObjektTiers
          apartmentId={objectID}
          classification={apartmentDetails?.classification}
          apartmentDetailKey={apartmentDetailKey}
        />

        <Text color="gray.300" pt="2">
          <Icon as={BiInfoCircle} color="blue.300" size="3.4em" mr="1" />
          {strings.last_v_office_data_sync}:&nbsp;
          {apartmentDetails?.updated_at || ''}
        </Text>

        <Stack sx={wrapperStyles}>
          <ObjektDetailsInfo
            informOwnerRepair={inform_owner_repairs}
            ownerRepairsAllowed={owner_repairs_allowed}
            allowedRepairsMaxPrice={allowed_repairs_max_price}
            objektInfo={apartmentDetails.info}
            ownerInfo={apartmentDetails.owner_info}
            notes={notes}
            objectImages={apartmentDetails?.images ?? []}
            isApartmentDetailLoading={isFetching}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ObjektDetails;
